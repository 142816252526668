import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'

import { css } from '@emotion/react'

import { colors, weights } from '@/styles/theme'
import { mediaColumns, Grid } from '@/styles/grid'
import { Text1Styles } from '@/styles/typography'
import { mediaUp } from '@/styles/media'
import { spacingSmallSection } from '@/styles/mixins'
import { useMarket } from '@/utils/multi-markets/context'

import NavipayIcon from '@/components/icons/Navipay'

const images = {
  en: 'https://cdn.naviparking.com/digital_parking/img/navipay-app-en.webp',
  pl: 'https://cdn.naviparking.com/digital_parking/img/navipay-app-pl.webp',
  de: 'https://cdn.naviparking.com/digital_parking/img/navipay-app-en.webp',
  ar: 'https://cdn.naviparking.com/digital_parking/img/navipay-app-en.webp'
}

interface Props {
  dark?: boolean
}

function Park(props: Props) {
  const { t } = useTranslation('common')
  const { dark = false } = props
  const { locale } = useMarket()
  const ParkImage = images[locale]

  return (
    <div css={[styles.container, dark && styles.containerDark]}>
      <Grid>
        <div css={styles.columnLeft}>
          <div css={styles.iconWrapper}>
            <NavipayIcon />
          </div>
          <p css={styles.description}>{parse(t('park.description'))}</p>
          <p css={styles.listTitle}>{t('park.listTitle')}</p>
          <ul css={styles.list}>
            {Array.isArray(t('park.list', { returnObjects: true })) &&
              t('park.list', { returnObjects: true }).map((el, index) => (
                <li css={styles.listItem} key={index}>
                  {parse(el)}
                </li>
              ))}
          </ul>
        </div>
        <div css={styles.columnRight}>
          <div css={styles.imageBackground}>
            <img src={ParkImage} alt={t('park.imageAlt')} />
          </div>
        </div>
      </Grid>
    </div>
  )
}

const styles = {
  container: css`
    ${Text1Styles};
    ${spacingSmallSection};
    overflow: hidden;
    width: 100%;
    color: ${colors.twilightBlue};
  `,
  containerDark: css`
    color: ${colors.white};
  `,
  columnLeft: css`
    ${mediaColumns({ xs: [12, 0], md: [5, 1] })};
  `,
  columnRight: css`
    ${mediaColumns({ xs: [10, 2], md: [6, 6], lg: [5, 7] })};
    margin-top: 40px;

    ${mediaUp('md')} {
      padding: 0 20px;
      margin-top: 0;
    }

    ${mediaUp('lg')} {
      padding: 0;
    }
  `,
  imageBackground: css`
    position: relative;
    width: 292px;
    height: 330px;
    z-index: 1;
    margin: 0 auto;

    ${mediaUp('lg')} {
      width: 583px;
      height: 660px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  `,
  description: css`
    margin: 18px 0;

    ${mediaUp('lg')} {
      margin: 40px 0;
    }
  `,
  list: css`
    list-style: none;
  `,
  listItem: css`
    position: relative;
    margin-top: 10px;
    padding-inline-start: 27px;

    ${mediaUp('lg')} {
      padding-inline-start: 54px;
    }

    &::before {
      content: '';
      position: absolute;
      background-color: ${colors.greenishCyan};
      left: 0;
      top: 0.45em;
      width: 18px;
      height: 5px;
      border-radius: 5px;

      ${mediaUp('lg')} {
        width: 34px;
        height: 8px;
        border-radius: 8px;
      }

      html[lang^='ar'] & {
        right: 0;
        left: unset;
      }
    }

    ${mediaUp('lg')} {
      margin-top: 20px;
    }
  `,
  listTitle: css`
    font-weight: ${weights.black};
  `,
  iconWrapper: css`
    display: flex;

    svg {
      width: 100px;
      height: 21px;

      ${mediaUp('lg')} {
        width: 220px;
        height: 45px;
      }
    }
  `
}

export default Park
